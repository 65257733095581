import React from "react";
import  Upload from "assets/images/financial-video-assets/upload.svg"
import  Transcribe from "assets/images/financial-video-assets/transcribe.svg"
import  Translate from "assets/images/financial-video-assets/translate.svg"

function Newtranslation() {
  return (
    <div className="flex items-center justify-center bg-darkBlue py-20">
      <div className="flex items-center justify-center max-w-screen-xl w-full 2xl:w-6/12 lg:w-7/12 flex-col px-4 xl:px-0">
        <h2 className="uppercase text-3xl lg:text-5xl text-white font-secondary font-bold w-full lg:w-8/12 text-center mb-12 lg:mb-20">
          How to <span className="text-orange">translate</span> your videos with
          Tomedes
        </h2>
        <div className="grid grid-cols-1 xl:grid-cols-3 sm:grid-cols-2 lg:gap-x-6">
          <div className=" text-center">
            <img src={Upload} alt="loading..." className="mx-auto my-3"/>
            <h3 className="text-2xl font-bold font-secondary text-white leading-8">
              Upload
            </h3>
            <p className="text-darkMist font-opensans leading-8">
              Simply upload your video at Tomedes, and we'll do the rest.
            </p>
          </div>
          <div className=" text-center">
            <img src={Transcribe} alt="loading..." className="mx-auto my-3"/>
            <h3 className="text-2xl font-bold font-secondary text-white leading-8">
              Transcribe
            </h3>
            <p className="text-darkMist font-opensans leading-8">
              Our project managers will assign your video to be transcribed by
              expert transcriptionists.
            </p>
          </div>
          <div className=" text-center">
            <img src={Translate} alt="loading..." className="mx-auto my-3"/>
            <h3 className="text-2xl font-bold font-secondary text-white leading-8">
              Translate
            </h3>
            <p className="text-darkMist font-opensans leading-8">
              We will match you with a video translator whose expertise is in
              the languages of your choosing.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Newtranslation;

import React from "react";

function VideoTranslator() {
  return (
    <div className="flex items-center justify-center bg-midBlue py-14 lg:py-24">
      <div className="grid grid-cols-1 lg:grid-cols-2 max-w-screen-xl w-full xl:w-7/12 px-4 xl:px-0">
        <div className="mb-5">
          <h2 className="text-3xl lg:text-4xl leading-8 lg:leading-[52px] font-secondary text-white font-bold mb-8 lg:mb-16 pr-0 lg:pr-20">
            Why opt for <br />
            <span className="text-orange">professional</span> video translators?
          </h2>
        </div>
        <div className="mb-5">
          <img
            src="https://tomedes.gumlet.io/frontend/images/services/traditional.webp"
            alt="loading..."
            className="hidden lg:inline-block mb-5"
          />
        </div>
        <div className="pr-0 lg:pr-24 mb-5">
          <h3 className="text-2xl font-secondary font-bold text-white mb-5">
            <span className="text-orange">Video</span> Is Our Canvas
          </h3>
          <p className="text-darkMist font-opensans leading-8">
            We create original translations, the source language transformed
            into a new language, through video rather than by using automatic
            translation software so that your translations are a work of art and
            not of a robot. We aim to inspire exploration into different
            languages through the visual form.
          </p>
        </div>

        <div className="pr-0 lg:pr-24 ">
          <h3 className="text-2xl font-secondary font-bold text-white mb-5">
            <span className="text-orange">Language</span> Is Our Game
          </h3>
          <p className="text-darkMist font-opensans leading-8">
            All translation requires the best, expert language skills of the
            translator or transcriber. So much more so in video transcription
            and videa translation, where the translator must be versed in two or
            more languages, including hard-to-find languages. Translation
            fluency in these languages are what Tomedes is all about, which aims
            to inspire the world in language learning and advocacy.
          </p>
        </div>
      </div>
    </div>
  );
}

export default VideoTranslator;

import React from "react";
import AcadmicInstruction from "../AcadmicInstruction";
import NewBanner from "./newBanner";
import VideoTranslator from "./videoTranslator";
import Newtranslation from "./newtranslation";
import SpeceficTranslation from "components/LocationReusableComp/speceficTranslation";
import WhatChooseUs from "components/homepage/whyChooseUs";
import Reviews from "components/homepage/reviews";
import VideoForm from "./videoForm";
import BeyondVideo from "./BeyondVideo";

function VideoTranslation() {
  return (
    <>
      <NewBanner
        backgroundCol="bg-midBlue"
        ftitle="Leave"
        midTitle="Video"
        secondTitle="Transcription and Translation to the Professionals"
        content="Fill out the form on the right side of the screen for your video to fall into good hands."
        Subcontent="Can't find what you're looking for? Contact us."
      />
      <AcadmicInstruction
        BackgroundCol="bg-darkBlue"
        colorHead="Trusted"
        head=" "
        secondHead="by Media and Creative
        Companies Worldwide"
        content="95,000 clients have entrusted Tomedes with their business. These include private individuals, small to medium businesses, and the largest corporations, leading brands, national and local governments. But most importantly: we're here for you. Right now."
      />
      <VideoTranslator />
      <Newtranslation />
      <SpeceficTranslation
        backgroundCol="bg-midBlue"
        FirstOrange=" "
        FirstWhite=" "
        ColorHead="Translate"
        SecondWhite=" your videos into over 120 languages and more than 950 language pairs"
        headColor="text-orange"
        linkName=" "
        Content="Leave your video transcription and translation to the hands of professional translators, who can translate in over 120 languages and more than 950 pairs. Tomedes includes rare languages, minority languages, and common languages in their repertoire. Through translation and transcription in various languages, your video can be more accessible to more viewers."
        getStarted
      />
      <VideoForm />
      <BeyondVideo />
      <WhatChooseUs backgroundCol="bg-darkBlue" />
      <Reviews backgroundCol="bg-midBlue" />
    </>
  );
}

export default VideoTranslation;

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMidForm } from "hooks/Forms/useMidForm";
import { useLocation } from "@reach/router";
import ThankYouPopup from "components/popup/thankYouPopup";
import Loader from "components/loader";
import formCommonPostData from "utils/form-common-post-data";
import { errorMessageEmail, patternEmail } from "utils/form-validation";

function VideoForm() {
  const [open, setOpen] = useState(false);
  const { status, mutateAsync } = useMidForm();
  const location = useLocation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const postData1 = {
      email: data.email,
      pagelink: location.href,
      consent: data.consent,
    };
    const postData = { ...postData1, ...formCommonPostData() };
    mutateAsync(postData).then((_) => {
      reset();
      setOpen(true);
    });
  };
  return (
    <div className="flex items-center justify-center bg-darkBlue py-24">
      <div className="flex items-center justify-center flex-col px-4 sm:px-0">
        <h2 className="text-white font-secondary text-3xl lg:text-4xl font-bold text-center mb-7">
          Take the next step.
        </h2>
        <p className="text-darkMist font-opensans mb-7 text-center">
          See what it's like to truly engage your audience. Translate your
          videos
        </p>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-wrap sm:flex-nowrap items-start justify-center"
        >
          <div className="mr-0 sm:mr-4 mb-6 sm:mb-0 w-full sm:w-[320px]">
            <input
              type="email"
              id="email"
              className="rounded h-[51px] w-full pl-4"
              placeholder="Enter email address here"
              {...register("email", {
                required: true,
                pattern: patternEmail,
              })}
            />
            {errors.email && (
              <span className="text-red text-xs pt-2 block">
                {errorMessageEmail}
              </span>
            )}
          </div>
          <input type="hidden" id="zc_gad" name="zc_gad" value="" />
          <button className="text-white font-bold bg-orange py-3 px-4 w-32 h-[51px]">
            {status === "loading" ? <Loader /> : "SUBMIT"}
          </button>
        </form>
      </div>
      <ThankYouPopup open={open} setOpen={setOpen} />
    </div>
  );
}
export default VideoForm;

import React from "react";

function BeyondVideo() {
  return (
    <div className="flex items-center justify-center py-24 bg-midBlue">
      <div className="flex items-center justify-center max-w-screen-xl w-full xl:w-7/12 flex-col px-4 xl:px-0">
        <h2 className="font-secondary font-bold text-white text-center text-3xl lg:text-4xl mb-12 xl:mb-24">
          Go beyond text
        </h2>
        <div className="grid grid-cols-1 lg:grid-cols-2 ">
          <div className="mb-16 lg:mb-32">
            <h3 className="text-3xl text-white font-secondary font-semibold mb-5">
              <span className="text-orange">Professional</span> <br />
              <span> Voice Overs</span>
            </h3>
            <p className="text-darkMist leading-8 pr-0 lg:pr-16">
              Voice over generators can mimic a human voice, but there's nothing
              closer to a human voice than a human. At Tomedes, we are inspired
              by our smart human voice actors who are trained professionally in
              voice acting for the best voice overs for your videos.
            </p>
          </div>
          <div className="mb-16 lg:mb-32">
            <h3 className="text-3xl text-white font-secondary font-semibold mb-5">
              <span className="text-orange">Immersive </span> <br />
              <span> Closed Captions</span>
            </h3>
            <p className="text-darkMist leading-8 pr-0 lg:pr-16">
              Our closed captions aim to inspire immersive experiences for the
              viewer that can go beyond text, allowing the hard of hearing or
              any ordinary viewer to experience the video. Closed captions are
              subtitled by expert subtitling translators so that the viewer can
              view the closed captions, in real-time, synced to the video.
            </p>
          </div>
          <div className="mb-16 lg:mb-32">
            <h3 className="text-3xl text-white font-secondary font-semibold mb-5">
              <span className="text-orange">On the dot </span> <br />
              <span> subtitles</span>
            </h3>
            <p className="text-darkMist leading-8 pr-0 lg:pr-16">
              Time sync can only be done by translators who are experienced in
              video software, tools, and programs. This process is used to
              indicate at what time in the video a circumstance occurs, which
              gives way to the accurate application of subtitles. In the
              accuracy and attention-to-detail of time syncing, we hope to
              inspire viewers in language acquisition through film, movie, or
              video.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BeyondVideo;

import Layout from "components/layout";
import VideoTranslation from "components/reusableComponents/VideoTransFolder/VideoTranslation";
import SEO from "components/seo";
import React from "react";

function index() {
  const title = "Fast Video Translation Services - Tomedes";
  const description =
    "Tomedes offers video translation services in 120+ languages, which include dubbing, subtitling, translating, and transcribing videos and audio.";
  const keywords = "";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug="/video-translation"
      />
      <VideoTranslation />
    </Layout>
  );
}

export default index;
